<template>
  <div class="overflow-hidden w-full">
    <img
      v-if="disableParallax"
      class="object-cover w-full h-full"
      data-scroll
      ref="image"
      :loading="isLazyloaded ? 'lazy' : ''"
      :srcset="srcset"
      :sizes="sizes"
      :alt="alt || 'Default alt tag'"
      :data-src="fallbackSrc"
      :width="renderWidth"
      :height="renderHeight"
      @load="onImageLoad"
    />
    <AnimationsGsapOnScroll
      class="w-full"
      v-else
    >
      <img
        class="object-cover w-full h-full"
        data-scroll
        ref="image"
        :loading="isLazyloaded ? 'lazy' : ''"
        :srcset="srcset"
        :sizes="sizes"
        :alt="alt || 'Default alt tag'"
        :data-src="fallbackSrc"
        :width="renderWidth"
        :height="renderHeight"
        @load="onImageLoad"
      />
    </AnimationsGsapOnScroll>
  </div>
</template>

<script setup>
import { ref, reactive, watchEffect, onMounted } from 'vue';
import imageUrlBuilder from '@sanity/image-url';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from './../tailwind.config';
import getImageDataFromRef from './../queries/getImageDataFromRef';

const props = defineProps({
  aspectRatios: {
    type: Array,
    default() {
      return [
        { sm: 'default' },
        { md: 'default' },
        { lg: 'default' },
        { xl: 'default' },
        { '2xl': 'default' },
        { '3xl': 'default' }
      ];
    }
  },
  refImage: {
    type: String,
    default: () => {}
  },
  isLazyloaded: {
    type: Boolean,
    default: false
  },
  disableParallax: {
    type: Boolean,
    default: false
  },
  alt: {
    type: String,
    default: ''
  }
});

const image = ref(null);
const picture = reactive({
  asset: null,
  hotspot: null,
  crop: null,
  width: null,
  height: null
});
const imageData = ref([]);
const srcset = ref(null);
const fallbackSrc = ref(null);
const sizes = ref(null);
const renderWidth = ref(null);
const renderHeight = ref(null);
const preloadHref = ref(null);
const sanity = useSanity();
const { currentBreakpoint } = useActiveBreakpoint();

onMounted(async () => {
  if (process.server) {
    return;
  }
  const query = getImageDataFromRef(props.refImage);
  const docReference = await sanity.fetch(query);
  const { asset, crop, hotspot } = docReference;
  picture.asset = { ...asset, crop, hotspot };
  picture.width = asset?.metadata?.dimensions?.width;
  picture.height = asset?.metadata?.dimensions?.height;
  imageData.value = generateImageData();
  srcset.value = generateSrcset();
  sizes.value = generateSizes();
});

const onImageLoad = () => {
  if (image.value) {
    renderWidth.value = image.value.offsetWidth;
    renderHeight.value = image.value.offsetHeight;
    fallbackSrc.value = generateSrc(renderWidth.value, renderHeight.value);
    preloadHref.value = preloadHref.value || fallbackSrc.value;
  }
};

watchEffect(() => {
  if (imageData.value) {
    preloadHref.value = imageData.value.find(
      (img) => img.breakpoint === currentBreakpoint
    )?.picture;
  }
});

const createBuilder = () => {
  return imageUrlBuilder(sanity.config);
};

const getAspectRatioHeight = (width, ratio) => {
  if (ratio === 'square') {
    return width;
  }

  if (ratio === 'panorama') {
    return Math.round((width / 147) * 36.75);
  }

  if (ratio.includes(':')) {
    const dimension = ratio.split(':');
    return Math.round((width / parseFloat(dimension[0])) * parseFloat(dimension[1]));
  }

  // if default, then we return the natural height of the image
  // TODO: this return value is not ideal
  return picture.asset?.metadata?.dimensions?.height;
};

const generateSrcset = () => {
  const srcsetAttr = imageData.value
    .map((img) => {
      const isCurrentBreakpoint = img.breakpoint === currentBreakpoint;
      const imgUrl = createBuilder()
        .image(picture.asset)
        .width(img.picture.w)
        .height(img.picture.h)
        .auto('format')
        .dpr(1)
        .fit('crop')
        .url();

      // this value is used in the head() and will set a prefetch attr
      preloadHref.value = isCurrentBreakpoint && imgUrl;

      return `${imgUrl} ${img.size}w`;
    })
    .join(', ');

  return srcsetAttr;
};

const generateSizes = () => {
  return imageData.value
    .map((img) => {
      return img.breakpoint === 'sm'
        ? `(min-width: 0w) and (max-width: ${img.size}w) ${img.size}w`
        : `(min-width: ${img.size}w) ${img.size}w`;
    })
    .join(', ');
};

const generateSrc = (width, height) => {
  return createBuilder()
    .image(picture.asset)
    .width(width)
    .height(height)
    .auto('format')
    .dpr(1)
    .fit('crop')
    .url();
};

const getAspectRatioByBreakpoint = (breakpoint) => {
  return props.aspectRatios.filter(
    (aspectRatio) => Object.keys(aspectRatio)[0] === breakpoint
  )[0]?.[breakpoint];
};

const generateImageData = () => {
  const {
    theme: { screens }
  } = resolveConfig(tailwindConfig);

  const tailwindBreakpoints = Object.keys(screens);
  return tailwindBreakpoints.map((breakpoint) => {
    const breakpointWidthInPx = parseInt(screens[breakpoint].replace('px', ''));
    // check if the natural size of the image is greater than the breakpoint.
    // If so, then we should ask for an image as big as the breakpoint
    // If it is smaller than the breakpoint, we load the natural image
    const aspectRatio = getAspectRatioByBreakpoint(breakpoint);

    const srcset = {
      breakpoint,
      size: breakpointWidthInPx,
      picture: { w: 0, h: 0 },
      aspectRatio
    };

    const pictureNaturalWidth = picture.width;
    // the picture's natural size is bigger than the current breakpoint viewport
    // so we need to resize it based on the breakpoint size as max-width.
    if (pictureNaturalWidth > breakpointWidthInPx) {
      srcset.picture.w = breakpointWidthInPx;
      if (srcset.aspectRatio === 'default') {
        // we calculate the height of the image preserving the natural aspect ratio
        srcset.picture.h = Math.round(breakpointWidthInPx * (picture.height / pictureNaturalWidth));
      } else {
        // we calculate height based on aspect ratio
        srcset.picture.h = getAspectRatioHeight(breakpointWidthInPx, srcset.aspectRatio);
      }
    } else {
      // if the image is smaller then the breakpoint screen size,
      // we want the natural sized image (not optimal for page rendering).
      srcset.picture.w = pictureNaturalWidth;
      srcset.picture.h = getAspectRatioHeight(pictureNaturalWidth, srcset.aspectRatio);
    }

    return srcset;
  });
};

const isValidRefImage = () => {
  return (
    (!!refImage && refImage !== '' && typeof refImage === 'string') || refImage instanceof String
  );
};
</script>

<style lang="postcss">
img {
  @apply w-full;
}
</style>
