<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useSettingsStore } from '~/stores/useSettingsStore';
// check if locale has changed and if so, reload load settings
const { locale } = useI18n();
const settingsStore = useSettingsStore();
const { loadSettings } = settingsStore;
onMounted(() => {
  if (process.env.NODE_ENV === 'development') {
    document.body.setAttribute('dev-mode', '');
  }
});

watch(locale, async () => {
  await loadSettings();
});
</script>

<style lang="postcss">
@keyframes rotateAnimation {
  0% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
  25% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  75% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
}

.page-transition {
  transform: rotate3d(0, 1, 0, 90deg);
  @apply bg-green;
  z-index: 999;
  animation: rotateAnimation 1.3s forwards;
}

.page-enter-active {
  transition: all 0.3s;
}

.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from {
  opacity: 0;
}

.page-leave-to {
  opacity: 0;
}

@keyframes showIfLoaded {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

/* .show-if-page-loaded {
  opacity: 0;
  animation: showIfLoaded 1.3s forwards;
  animation-delay: 0.3s;
} */

[dev-mode] {
  @apply before:fixed before:bottom-0 before:left-0 before:py-[5px] before:px-[10px] before:z-[9999] before:bg-black  before:text-white before:text-[12px] before:content-['Dev\00a0Mode'];
  @apply after:fixed after:bottom-0 after:right-0 after:py-[5px] after:px-[10px] after:z-[9999] after:bg-black  after:text-white after:text-[12px] after:content-['sm'] md:after:content-['md'] lg:after:content-['lg'] xl:after:content-['xl'] 2xl:after:content-['2xl'] 3xl:after:content-['3xl'];
}
</style>
